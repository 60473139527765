import { React, useEffect } from "react";
import Header from "./Header1";
import Footer from "./Footer";
import { Container } from "react-bootstrap";
import { Philosophylist } from "../Actions/cmsFrontAxios";
import { useState } from "react";

const Philosophy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  });

  useEffect(() => {
    GetPhilosophylists();
  }, []);

  const [Philosophydata, setPhilosophydata] = useState();

  const GetPhilosophylists = async () => {
    try {
      let resp = await Philosophylist();
      console.log("GetPhilosophylistsGetPhilosophylists", resp.data);
      setPhilosophydata(resp.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <>
      <Header />

      <article className="innerpages">
        <section className="explaincontents">
          <Container>
            <h1 className="innerhead">Node AI philosophy</h1>
            <div className="paracontents">


            {Philosophydata &&
                Philosophydata.map((item) => {
                  return (
                    <p
                      className="content"
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}></p>
                  );
                })}



              {/* <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p> */}


              {/* <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p>
              <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p>
              <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p>
              <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p>
              <p className="content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit.
                Quibusdam laboriosam ipsa non unde asperiores consequuntur
                nesciunt deserunt nulla aliquid veniam quaerat, quos neque,
                fugiat quasi vel dignissimos? Deserunt, adipisci explicabo!
              </p> */}



            </div>
          </Container>
        </section>
      </article>

      <Footer />
    </>
  );
};

export default Philosophy;
