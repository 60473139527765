import { React, useState, useEffect } from 'react';
import Header from './Header1';
import Footer from './Footer';
import { Col, Container, Row, Accordion, Carousel } from 'react-bootstrap';
import Lottie from "lottie-react";
import Images from './Simage';
import { Link } from 'react-router-dom';
import { Roadmaplists, getCmslists, getcmssingledata } from '../Actions/cmsFrontAxios';
import Marquee from 'react-fast-marquee';
import Bannervideo from "../Assets/images/bannervideo.mp4";


const Home = () => {

  const [active, setActive] = useState(1);
  // const [activetwo, setActivetwo] = useState("1");
  const [AllCmsdata, setAllCmsdata] = useState([])

  useEffect(() => {
    GetCmslists()
    GetcmsparicularData()
    GetRoadmaplists()
  }, [])
  const [features, setFeatures] = useState([
    {
      feathead: "lorem ipsum two",
      featdesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam leo turpis, pharetra eget tortor at, viverra accumsan sapien. Suspendisse cursus tempus nisl, non fringilla purus bibendum tincidunt. Sed eget arcu volutpat, elementum magna eu, accumsan lectus. Praesent ultricies luctus ligula, in maximus velit eleifend ac. Duis vestibulum vestibulum dolor, a tincidunt sapien tincidunt non. Mauris vehicula dui quam. Phasellus quis ultricies lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent semper ligula iaculis lacus sagittis, ut faucibus sem commodo."
    },
    {
      feathead: "lorem ipsum two",
      featdesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam leo turpis, pharetra eget tortor at, viverra accumsan sapien. Suspendisse cursus tempus nisl, non fringilla purus bibendum tincidunt. Sed eget arcu volutpat, elementum magna eu, accumsan lectus. Praesent ultricies luctus ligula, in maximus velit eleifend ac. Duis vestibulum vestibulum dolor, a tincidunt sapien tincidunt non. Mauris vehicula dui quam. Phasellus quis ultricies lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent semper ligula iaculis lacus sagittis, ut faucibus sem commodo."
    },
    {
      feathead: "Lorem ipsum dolor three",
      featdesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam leo turpis, pharetra eget tortor at, viverra accumsan sapien. Suspendisse cursus tempus nisl, non fringilla purus bibendum tincidunt. Sed eget arcu volutpat, elementum magna eu, accumsan lectus. Praesent ultricies luctus ligula, in maximus velit eleifend ac. Duis vestibulum vestibulum dolor, a tincidunt sapien tincidunt non. Mauris vehicula dui quam. Phasellus quis ultricies lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent semper ligula iaculis lacus sagittis, ut faucibus sem commodo."
    },
    {
      feathead: "Lorem ipsum sit amet four",
      featdesc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam leo turpis, pharetra eget tortor at, viverra accumsan sapien. Suspendisse cursus tempus nisl, non fringilla purus bibendum tincidunt. Sed eget arcu volutpat, elementum magna eu, accumsan lectus. Praesent ultricies luctus ligula, in maximus velit eleifend ac. Duis vestibulum vestibulum dolor, a tincidunt sapien tincidunt non. Mauris vehicula dui quam. Phasellus quis ultricies lorem. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent semper ligula iaculis lacus sagittis, ut faucibus sem commodo."
    }
  ]);
  console.log("featuresfeatures", features);

  // const rotateactivefunc = async (box) => {
  //   console.log("frgfsgdfg",box);
  //   (box !== active) && setActive(box);
  //   setActivetwo(box);



  // }


  const GetCmslists = async () => {
    try {
      let resp = await getCmslists();
      console.log("getCmslists", resp.data);
      setAllCmsdata(resp.data)
    } catch (error) {
      console.log("error", error);
    }
  }


  const GetcmsparicularData = async () => {
    try {
      let resp = await getcmssingledata();
      console.log("getcmssingledata", resp.data);
      setFeatures(resp.data)
    } catch (error) {
      console.log("error", error);
    }
  }


  const GetRoadmaplists = async () => {
    try {
      let resp = await Roadmaplists();
      console.log("GetRoadmaplists", resp.data);
      setRoadmap(resp.data)
    } catch (error) {
      console.log("error", error);
    }
  }

  const CmsData = {
    Welcome: AllCmsdata.find((val) => val.identifier == "welcome"),
    Poolinfo: AllCmsdata.find((val) => val.identifier == "poolinfo"),
    Trusted: AllCmsdata.find((val) => val.identifier == "trustedby"),
    Ourfeature: AllCmsdata.find((val) => val.identifier == "ourfeatures"),
    Roadmap: AllCmsdata.find((val) => val.identifier == "roadmap"),
    Footercontent: AllCmsdata.find((val) => val.identifier == "footercontent"),
    Homemid: AllCmsdata.find((val) => val.identifier == "home_middle"),


  }
  console.log("cmsDatavalues", CmsData);




  const [info] = useState([
    {
      sell: "sell",
      days: 90,
      reward: "0.05 %"
    },
    {
      sell: "sell",
      days: 180,
      reward: "0.25 %"
    },
    {
      sell: "sell",
      days: 365,
      reward: "0.75 %"
    }
  ]);

  const [roadmap, setRoadmap] = useState([
    {
      roadtext: "January 2024"
    },
    {
      roadtext: "March 2024"
    },
    {
      roadtext: "May 2024"
    },
    {
      roadtext: "June 2024"
    },
    {
      roadtext: "August 2024"
    },
    {
      roadtext: "October 2024"
    },
  ]);

  const [winresize, setWinresize] = useState(window.innerWidth);

  useEffect(() => {

    const handlechange = () => {
      setWinresize(window.innerWidth);
    }

    window.addEventListener("resize", handlechange);
    return () => {
      window.removeEventListener("resize", handlechange);
    }
  }, []);


  return (
    <>
      <Header />

      <section className='bannersection'>
        <Container>
          {/* <Lottie animationData={Images.bannercenterlines} loop={true} className='bannersection_linesimg'></Lottie> */}
          <div className='bannersection_contents'>
            <Row className='justify-content-center align-items-center'>
              <Col xs={12} sm={12} md={12} lg={6} className='mb-4 mb-lg-0'>
                <div className='text-center text-lg-start'>
                  <h1 className='bannersection_head'>{CmsData?.Welcome?.Title}</h1>
                  <p className='content mt-3 mb-4'>
                    {CmsData?.Welcome?.Content}
                  </p>
                  <Link to="/whitepaper" className="sitebtn text-center">Learn more</Link>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6}>
                <Lottie animationData={Images.bannercenter} loop={true} className='bannersection_centerimg'></Lottie>
              </Col>
            </Row>
          </div>
        </Container>
        <video className='bannersection_video' autoPlay muted loop controls={false}>
          <source src={Bannervideo} type='video/mp4'></source>
        </video>
      </section>
      <section className='partners'>
        <div>
          <Marquee direction='left'>
            {Images.partnersimg.map((pimg) => (
              <img src={pimg.partnerimg} alt='Partner image' className='img-fluid mx-5' />
            ))}
          </Marquee>
        </div>
      </section>
      <section className='info'>
        <Container fluid>
          <div className='info_bg'>
            <Lottie animationData={Images.infobg} loop={true} className='info_bgimg'></Lottie>
            <div className='container info_contentbox'>
              <div>
                <Row>
                  <Col xs={12} md={12} lg={6}>
                    <div className='text-center text-lg-start'>
                      <div className='secthead'>
                        <h2 className='sectionhead greenbg'>{CmsData?.Poolinfo?.Title}</h2>
                        <Lottie animationData={Images.headline} loop={true} className='sectionheadline'></Lottie>
                      </div>
                      <p className='content text-white'>
                        {CmsData?.Poolinfo?.Content}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className='info_center'>
                  <Row className='align-items-center'>
                    <Col xs={12} sm={12} md={12} lg={6}>
                      {winresize > 991 ? <Lottie animationData={Images.infoweb} loop={true} className='info_centercircle'></Lottie> : <Lottie animationData={Images.infomobile} loop={true} className='info_centercircle'></Lottie>}
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6}>
                      <div className='info_centerbgtop'>
                        {info.map((io, i) => (
                          <div className={`${'info_centerbg' + i} info_centerbg`}>
                            <p className='content info_content'>{io.sell + (i + 1)} ({io.days} Days Locked staking)</p>
                            <span className='info_centerbg_span'>Reward: {io.reward}</span>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>

        </Container>
      </section>


      <section className='trustby'>
        <Container>
          <Row className='justify-content-center'>
            <Col xs={12} sm={12} md={12} lg={8}>
              <div className='text-center'>
                <div className='secthead'>
                  <h2 className='sectionhead'>{CmsData?.Trusted?.Title}</h2>
                  <Lottie animationData={Images.headline} loop={true} className='sectionheadline'></Lottie>
                </div>
                <p className='content'>
                  {CmsData?.Trusted?.Content}
                  {/* <Link to="/about" className='ms-2 underline'>Read more</Link> */}
                </p>
              </div>
            </Col>
          </Row>
          <Lottie animationData={Images.trust} loop={true} className='trustby_img'></Lottie>
        </Container>
      </section>

      <section className='features'>
        <Container fluid>
          <div className='features_bg'>
            <Lottie animationData={Images.featurebg} loop={true} className='features_bgimg'></Lottie>
            <div className='features_contentbox'>
              <div>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={12} md={12} lg={8}>
                    <div className='text-center'>
                      <div className='secthead'>
                        <h2 className='sectionhead greenbg'>{CmsData?.Ourfeature?.Title}</h2>
                        <Lottie animationData={Images.headline} loop={true} className='sectionheadline'></Lottie>
                      </div>
                      <p className='content text-white'>
                        {CmsData?.Ourfeature?.Content}
                        {/* <Link to="/philosophy" className='ms-2 underline'>Read more</Link> */}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className='features_centerbox'>
                  <div className='features_rotatebox'>
                    <div className='features_rotate'></div>
                    {console.log(active, "active")}
                    <Lottie animationData={Images.featlogo} loop={true} className='rotateboxlogo'></Lottie>
                    <div className={`box${active} rotateboxparent`}>
                      <div className={`${active == 1 && "active"} rotatebox one`} onClick={() => setActive(1)}
                      >
                        <div>
                          <img src={Images.featimgone} alt='Feature Icon' className='rotateicon' />
                        </div>
                      </div>
                      <div className={`${active == 2 && "active"} rotatebox two`} onClick={() => setActive(2)}>
                        <div>
                          <img src={Images.featimgfour} alt='Feature Icon' className='rotateicon' />
                        </div>
                      </div>
                      <div className={`${active == 3 && "active"} rotatebox three`} onClick={() => setActive(3)}>
                        <div>
                          <img src={Images.featimgthree} alt='Feature Icon' className='rotateicon' />
                        </div>
                      </div>
                      <div className={`${active == 4 && "active"} rotatebox four`} onClick={() => setActive(4)}>
                        <div>
                          <img src={Images.featimgtwo} alt='Feature Icon' className='rotateicon' />
                        </div>
                      </div>
                    </div>
                  </div>
                  <Lottie animationData={Images.featurevertical} loop={true} className='features_vimg'></Lottie>
                  <div className='featurebox'>
                    <Carousel activeIndex={active - 1} controls={false} indicators={false} touch={false}>
                      {features.map((ft) => (
                        <Carousel.Item>
                          <div className="featbox">
                            <p className='feathead'>{ft.Title}</p>
                            <div className='featbody'>
                              <p className='content pt-2'>{ft.Content}</p>
                            </div>
                          </div>
                        </Carousel.Item>

                      ))}
                    </Carousel>


                  </div>
                </div>
              </div>

            </div>
          </div>


        </Container>
      </section>


      <section className='clients'>
        <Container fluid>
          <div className='clients_bg'>
            <Lottie animationData={Images.clientsbg} className='clients_bgimg'></Lottie>
            <div className='clients_centerbox'>
              <Row className='justify-content-center'>
                <Col xs={12} sm={12} md={12} lg={8}>
                  <div className='text-center position-relative'>
                    <div className='secthead'>
                      <h2 className='sectionhead'>{CmsData?.Homemid?.Title}</h2>
                      <Lottie animationData={Images.headline} loop={true} className='sectionheadline'></Lottie>
                    </div>
                    <p className='content'>
                      {CmsData?.Homemid?.Content}
                      {/* <Link to="/charter" className='ms-2 underline'>Read more</Link> */}
                    </p>
                  </div>
                </Col>
              </Row>
              <div>
                <Lottie animationData={Images.featlogo} loop={true} className='rotateboxlogo'></Lottie>
                <Lottie animationData={Images.clients} loop={true} className='clients_img'></Lottie>
              </div>
            </div>
          </div>
        </Container>
      </section>

      <section className='roadmap'>
        <Container fluid>
          <div className='roadmap_bg'>
            <Lottie animationData={Images.roadmapbg} className='roadmap_bgimg'></Lottie>
            <div className='roadmap_centerbox'>
              <div>
                <Row className='justify-content-center'>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className='text-center position-relative'>
                      <div className='secthead'>
                        <h2 className='sectionhead'>{CmsData?.Roadmap?.Title}</h2>
                        <Lottie animationData={Images.headline} loop={true} className='sectionheadline'></Lottie>
                      </div>
                    </div>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={8}>
                    <div className='text-center'>
                      <p className='content'>
                        {CmsData?.Roadmap?.Content}
                      </p>
                    </div>
                  </Col>
                </Row>
                <div className='roadmap_accordionbox'>
                  <Accordion defaultActiveKey={false}>
                    {roadmap.map((rp, i) => (
                      <Accordion.Item eventKey={i + 1}>
                        <Accordion.Button>
                          <div className='accordion_divbuttonhead'>
                            <span className='accordion_buttonhead'>{rp?.heading}</span>
                          </div>
                          <div className='accordion_divbuttonnum'>
                            <span className='accordion_buttonnum'>{"0" + (i + 1)}</span>
                          </div>
                        </Accordion.Button>
                        <Accordion.Body>
                          <p className='content'>
                            <div dangerouslySetInnerHTML={{ __html: rp?.description }}></div>
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>


      <Footer />


    </>
  )
}

export default Home;